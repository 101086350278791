<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('CAMPAIGN.ADD.TITLE')"
      :header-content="$t('CAMPAIGN.ADD.DESC')"
    />
    <form class="flex flex-col w-full" @submit.prevent="addCampaign">
      <div class="w-full">
        <woot-input
          v-model="title"
          :label="$t('CAMPAIGN.ADD.FORM.TITLE.LABEL')"
          type="text"
          :class="{ error: $v.title.$error }"
          :error="$v.title.$error ? $t('CAMPAIGN.ADD.FORM.TITLE.ERROR') : ''"
          :placeholder="$t('CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER')"
          @blur="$v.title.$touch"
        />

        <div
          v-if="isOngoingType || isSms || isLine || isFb"
          class="editor-wrap"
        >
          <label>
            {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.LABEL') }}
          </label>
          <div>
            <woot-message-editor
              v-model="message"
              class="message-editor"
              :class="{ editor_warning: $v.message.$error }"
              :placeholder="$t('CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER')"
              @blur="$v.message.$touch"
            />
            <span v-if="$v.message.$error" class="editor-warning__message">
              {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ERROR') }}
            </span>
          </div>
        </div>

        <div v-if="isLine || isFb" class="w-full">
          <file-upload
            ref="upload"
            v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
            :size="4096 * 4096"
            :accept="allowedFileTypes"
            :multiple="true"
            :drop="true"
            :drop-directory="false"
            :data="{
              direct_upload_url: '/rails/active_storage/direct_uploads',
              direct_upload: true,
            }"
            @input-file="onIndirectFileUpload"
          >
            <woot-button
              v-if="true"
              class-names="button--upload"
              :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
              icon="attach"
              emoji="📎"
              color-scheme="secondary"
              variant="smooth"
              size="small"
            />
          </file-upload>

          <div
            v-if="hasAttachments"
            class="attachment-preview-box"
            @paste="onPaste"
          >
            <attachment-preview
              :attachments="attachedFiles"
              :remove-attachment="removeAttachment"
            />
          </div>
        </div>

        <label v-if="isFb" :class="{ error: $v.selectedMessageType.$error }">
          {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.TYPE.GUI.LABEL') }}
          <select v-model="selectedMessageType">
            <option v-for="item in messageTypes" :key="item" :value="item">
              {{ $t(`CAMPAIGN.ADD.FORM.MESSAGE.TYPE.${item}`) }}
            </option>
          </select>
          <span v-if="$v.selectedMessageType.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.TYPE.GUI.ERROR') }}
          </span>
        </label>

        <label
          v-if="isFb && selectedMessageType === 'MESSAGE_TAG'"
          :class="{ error: $v.selectedMessageTag.$error }"
        >
          {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.TAG.GUI.LABEL') }}
          <select v-model="selectedMessageTag">
            <option v-for="item in messageTags" :key="item" :value="item">
              {{ $t(`CAMPAIGN.ADD.FORM.MESSAGE.TAG.${item}`) }}
            </option>
          </select>
          <span v-if="$v.selectedMessageTag.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.TAG.GUI.ERROR') }}
          </span>
        </label>

        <label v-if="isFb">
          <div>{{ $t('CAMPAIGN.ADD.FORM.MESSAGE.IS_BEFORE_24H.LABEL') }}</div>
          <div class="flex items-center gap-2">
            <input
                id="is_before_24h_when_tab_is_inactive"
                v-model="isBefore24h"
                class="message_filter--checkbox"
                type="checkbox"
            />
            <label for="is_before_24h_when_tab_is_inactive">
              {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.IS_BEFORE_24H.CONTENT') }}
            </label>
          </div>
        </label>

        <label :class="{ error: $v.selectedInbox.$error }">
          {{ $t('CAMPAIGN.ADD.FORM.INBOX.LABEL') }}
          <select
            v-model="selectedInbox"
            :class="{ 'mb-1': $v.selectedInbox.$error }"
            @change="onChangeInbox($event)"
          >
            <option v-for="item in inboxes" :key="item.name" :value="item.id">
              {{ item.name }}
            </option>
          </select>
          <span v-if="$v.selectedInbox.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.INBOX.ERROR') }}
          </span>
        </label>

        <label
          v-if="isWhatsapp"
          :class="{ error: $v.selectedWhatsappTemplate.$error }"
        >
          {{ $t('CAMPAIGN.ADD.FORM.WHATSAPP.LABEL') }}
          <div class="multiselect-wrap--small">
            <multiselect
              v-model="selectedWhatsappTemplate"
              class="no-margin"
              track-by="id"
              label="name"
              :placeholder="'Select'"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              deselect-label=""
              :max-height="160"
              :options="whatsappTemplateMessages"
              :allow-empty="false"
              :option-height="104"
              @input="onChangeTemplate"
            />
          </div>
          <span v-if="$v.selectedWhatsappTemplate.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.WHATSAPP.ERROR') }}
          </span>
        </label>

        <div v-if="isWhatsapp && selectedWhatsappTemplate" class="w-full mt-4">
          <div v-if="hasHeaderTemplate(selectedWhatsappTemplate)">
            <div
              class="preview-item flex p-1 bg-slate-50 dark:bg-slate-800 rounded-md w-[15rem] mb-1 mt-2"
            >
              <div class="max-w-[4rem] flex-shrink-0 w-6 flex items-center">
                <img
                  v-if="
                    selectedWhatsappTemplate.components[0]['format'] === 'IMAGE'
                  "
                  class="image-thumb"
                  :src="
                    selectedWhatsappTemplate.components[0]['example'][
                      'header_handle'
                    ][0]
                  "
                />
                <span v-else class="w-6 h-6 text-lg relative -top-px text-left">
                  📄
                </span>
              </div>
              <div
                class="max-w-[90%] min-w-[50%] overflow-hidden text-ellipsis ml-2"
              >
                <span
                  class="h-4 overflow-hidden text-sm font-medium text-ellipsis whitespace-nowrap"
                >
                  {{
                    selectedWhatsappTemplate.components[0]['example'][
                      'header_handle'
                    ][0]
                      .split('/')
                      .pop()
                  }}
                </span>
              </div>
            </div>
          </div>
          <textarea
            v-model="processedString"
            rows="4"
            readonly
            class="template-input"
          />
          <div v-if="variables" class="template__variables-container">
            <p class="variables-label">
              {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_LABEL') }}
            </p>
            <div
              v-for="(variable, key) in processedParams"
              :key="key"
              class="template__variable-item"
            >
              <span class="variable-label">
                {{ key }}
              </span>
              <woot-input
                v-model="processedParams[key]"
                type="text"
                class="variable-input"
                :styles="{ marginBottom: 0 }"
              />
            </div>
            <p v-if="$v.selectedWhatsappTemplate.$error" class="error">
              {{ $t('WHATSAPP_TEMPLATES.PARSER.FORM_ERROR_MESSAGE') }}
            </p>
          </div>
        </div>

        <section v-if="isOneOffType">
          <label class="multiselect-wrap--small">
            {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.LABEL') }}
          </label>
          <div
            class="w-full p-4 mb-4"
            :class="getInputErrorClass($v.selectedAudience.$error)"
          >
            <div v-for="(selectedItem, i) in selectedAudience" :key="i">
              <template v-if="i > 0">
                <div
                  class="flex items-center justify-center relative my-2.5 mx-0"
                >
                  <hr
                    class="w-full absolute border-b border-solid border-slate-75 dark:border-slate-800"
                  />
                  <select
                    v-model="selectedAudienceOperator[i - 1]"
                    class="bg-white dark:bg-slate-900 mb-0 w-auto relative text-slate-800 dark:text-slate-100 border-slate-75 dark:border-slate-600"
                  >
                    <option value="and">
                      {{ $t('FILTER.QUERY_DROPDOWN_LABELS.AND') }}
                    </option>
                    <option value="or">
                      {{ $t('FILTER.QUERY_DROPDOWN_LABELS.OR') }}
                    </option>
                  </select>
                </div>
              </template>
              <div class="flex items-center multiselect-wrap--small">
                <multiselect
                  v-model="selectedAudience[i]"
                  class="no-margin"
                  :options="audienceList"
                  track-by="id"
                  label="title"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :hide-selected="true"
                  :placeholder="$t('CAMPAIGN.ADD.FORM.AUDIENCE.PLACEHOLDER')"
                  selected-label
                  :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                  :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                  @blur="$v.selectedAudience.$touch"
                  @select="$v.selectedAudience.$touch"
                />
                <woot-button
                  icon="dismiss"
                  variant="clear"
                  color-scheme="secondary"
                  @click="() => removePeerAudience(i)"
                />
              </div>
            </div>
            <div class="mt-2">
              <woot-button
                icon="add"
                color-scheme="success"
                variant="smooth"
                size="small"
                @click.prevent="appendNewPeerAudience"
              >
                {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.AUDIENCE_BUTTON_LABEL') }}
              </woot-button>
            </div>
            <span v-if="$v.selectedAudience.$error" class="audience-error">
              {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.ERROR') }}
            </span>
          </div>
        </section>

        <label
          v-if="isOngoingType"
          :class="{ error: $v.selectedSender.$error }"
        >
          {{ $t('CAMPAIGN.ADD.FORM.SENT_BY.LABEL') }}
          <select v-model="selectedSender">
            <option
              v-for="sender in sendersAndBotList"
              :key="sender.name"
              :value="sender.id"
            >
              {{ sender.name }}
            </option>
          </select>
          <span v-if="$v.selectedSender.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.SENT_BY.ERROR') }}
          </span>
        </label>

        <label v-if="isOneOffType">
          {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL') }}
          <woot-date-time-picker
            :value="scheduledAt"
            :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
            :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
            @change="onChange"
          />
        </label>

        <woot-input
          v-if="isOngoingType"
          v-model="endPoint"
          :label="$t('CAMPAIGN.ADD.FORM.END_POINT.LABEL')"
          type="text"
          :class="{ error: $v.endPoint.$error }"
          :error="
            $v.endPoint.$error ? $t('CAMPAIGN.ADD.FORM.END_POINT.ERROR') : ''
          "
          :placeholder="$t('CAMPAIGN.ADD.FORM.END_POINT.PLACEHOLDER')"
          @blur="$v.endPoint.$touch"
        />
        <woot-input
          v-if="isOngoingType"
          v-model="timeOnPage"
          :label="$t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.LABEL')"
          type="text"
          :class="{ error: $v.timeOnPage.$error }"
          :error="
            $v.timeOnPage.$error
              ? $t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.ERROR')
              : ''
          "
          :placeholder="$t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.PLACEHOLDER')"
          @blur="$v.timeOnPage.$touch"
        />
        <label v-if="isOngoingType">
          <input
            v-model="enabled"
            type="checkbox"
            value="enabled"
            name="enabled"
          />
          {{ $t('CAMPAIGN.ADD.FORM.ENABLED') }}
        </label>
        <label v-if="isOngoingType">
          <input
            v-model="triggerOnlyDuringBusinessHours"
            type="checkbox"
            value="triggerOnlyDuringBusinessHours"
            name="triggerOnlyDuringBusinessHours"
          />
          {{ $t('CAMPAIGN.ADD.FORM.TRIGGER_ONLY_BUSINESS_HOURS') }}
        </label>
      </div>

      <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
        <woot-button :is-loading="uiFlags.isCreating">
          {{ $t('CAMPAIGN.ADD.CREATE_BUTTON_TEXT') }}
        </woot-button>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import campaignMixin from 'shared/mixins/campaignMixin';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import { URLPattern } from 'urlpattern-polyfill';
import { CAMPAIGNS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview';
import FileUpload from 'vue-upload-component';
import {
  ALLOWED_FILE_TYPES,
  MAXIMUM_FILE_UPLOAD_SIZE,
} from 'shared/constants/messages';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';

const allKeysRequired = value => {
  const keys = Object.keys(value);
  return keys.every(key => value[key]);
};
export default {
  components: {
    WootDateTimePicker,
    WootMessageEditor,
    FileUpload,
    AttachmentPreview,
  },
  mixins: [alertMixin, campaignMixin],
  data() {
    return {
      title: '',
      message: '',
      selectedSender: 0,
      selectedInbox: null,
      selectedMessageType: null,
      selectedMessageTag: null,
      isBefore24h: false,
      selectedWhatsappTemplate: null,
      processedParams: {},
      cachedRules: {},
      endPoint: '',
      timeOnPage: 10,
      show: true,
      enabled: true,
      triggerOnlyDuringBusinessHours: false,
      scheduledAt: null,
      selectedAudience: [[]],
      selectedAudienceOperator: [],
      senderList: [],
      attachedFiles: [],
      messageTypes: ['RESPONSE', 'UPDATE', 'MESSAGE_TAG'],
      messageTags: [
        'ACCOUNT_UPDATE',
        'CONFIRMED_EVENT_UPDATE',
        'POST_PURCHASE_UPDATE',
      ],
    };
  },
  validations() {
    let commonValidations = {
      title: {
        required,
      },
      selectedInbox: {
        required,
      },
    };
    if (this.isOneOffType && (this.isSms || this.isLine || this.isFb)) {
      commonValidations = {
        ...commonValidations,
        message: {
          required,
        },
      };
    }
    if (this.isOneOffType && this.isWhatsapp) {
      console.log('11111111');
      commonValidations = {
        ...commonValidations,
        selectedWhatsappTemplate: {
          required,
        },
        processedParams: {
          requiredIfKeysPresent: requiredIf('variables'),
          allKeysRequired,
        },
      };
    }

    if (this.isOneOffType && this.isFb) {
      console.log('2222222');
      console.log(this.selectedMessageType);
      commonValidations = {
        ...commonValidations,
        selectedMessageType: {
          required,
        },
      };
      if (this.selectedMessageType === 'MESSAGE_TAG') {
        commonValidations = {
          ...commonValidations,
          selectedMessageTag: {
            required,
          },
        };
      }
    }

    if (this.isOngoingType) {
      return {
        ...commonValidations,
        message: {
          required,
        },
        selectedSender: {
          required,
        },
        endPoint: {
          required,
          shouldBeAValidURLPattern(value) {
            try {
              // eslint-disable-next-line
              new URLPattern(value);
              return true;
            } catch (error) {
              return false;
            }
          },
          shouldStartWithHTTP(value) {
            if (value) {
              return (
                value.startsWith('https://') || value.startsWith('http://')
              );
            }
            return false;
          },
        },
        timeOnPage: {
          required,
        },
      };
    }
    return {
      ...commonValidations,
      selectedAudience: {
        isEmpty() {
          return !!this.selectedAudience.flat().length;
        },
      },
    };
  },
  computed: {
    hasAttachments() {
      return this.attachedFiles.length;
    },
    allowedFileTypes() {
      return ALLOWED_FILE_TYPES;
    },
    ...mapGetters({
      agentBots: 'agentBots/getBots',
      uiFlags: 'campaigns/getUIFlags',
      audienceList: 'labels/getLabels',
    }),
    whatsappTemplateMessages() {
      return this.$store.getters['inboxes/getWhatsAppTemplates'](
        this.selectedInbox
      ).filter(template => template.status.toLowerCase() === 'approved');
    },
    inboxes() {
      if (this.isOngoingType) {
        return this.$store.getters['inboxes/getWebsiteInboxes'];
      }
      return [
        ...this.$store.getters['inboxes/getSMSInboxes'],
        ...this.$store.getters['inboxes/getWhatsappInboxes'],
        ...this.$store.getters['inboxes/getLineInboxes'],
        ...this.$store.getters['inboxes/getFbInboxes'],
      ];
    },
    sendersAndBotList() {
      return [
        {
          id: 0,
          name: 'Bot',
        },
        ...this.senderList,
      ];
    },
    isSms() {
      return (
        !this.selectedInbox ||
        (this.selectedInbox &&
          this.inboxes.filter(
            inbox =>
              inbox.id === this.selectedInbox &&
              (inbox.channel_type === 'Channel::Whatsapp' ||
                inbox.channel_type === 'Channel::Line')
          ).length === 0)
      );
    },
    isWhatsapp() {
      return (
        this.selectedInbox &&
        this.inboxes.filter(
          inbox =>
            inbox.id === this.selectedInbox &&
            inbox.channel_type === 'Channel::Whatsapp'
        ).length === 1
      );
    },
    isLine() {
      return (
        this.selectedInbox &&
        this.inboxes.filter(
          inbox =>
            inbox.id === this.selectedInbox &&
            inbox.channel_type === 'Channel::Line'
        ).length === 1
      );
    },
    isFb() {
      return (
        this.selectedInbox &&
        this.inboxes.filter(
          inbox =>
            inbox.id === this.selectedInbox &&
            inbox.channel_type === 'Channel::FacebookPage'
        ).length === 1
      );
    },
    templateString() {
      return (
        this.selectedWhatsappTemplate?.components.find(
          component => component.type === 'BODY'
        ).text || ''
      );
    },
    headerParameters() {
      let headerTemplate = this.selectedWhatsappTemplate.components.find(
        component =>
          component.type === 'HEADER' &&
          ['IMAGE', 'VIDEO', 'DOCUMENT'].includes(component.format)
      );
      if (headerTemplate) {
        let parameter = { type: headerTemplate.format.toLowerCase() };
        parameter[parameter.type] = {
          link: decodeURIComponent(headerTemplate.example.header_handle[0]),
        };
        if (this.selectedWhatsappTemplate?.media_id) parameter[parameter.type].id = this.selectedWhatsappTemplate.media_id;

        return [parameter];
      }
      return [];
    },
    processedString() {
      let tmpString = this.templateString;
      if (Object.keys(this.cachedRules).length > 0) {
        tmpString = tmpString.replace(/{{([^}]+)}}/g, (match, variable) => {
          const variableKey = this.processVariable(variable);
          return this.cachedRules[variableKey] || `{{${variable}}}`;
        });
      }

      return tmpString.replace(/{{([^}]+)}}/g, (match, variable) => {
        const variableKey = this.processVariable(variable);
        return this.processedParams[variableKey] || `{{${variable}}}`;
      });
    },
    variables() {
      let tmpString = this.templateString;
      if (Object.keys(this.cachedRules).length > 0) {
        tmpString = tmpString.replace(/{{([^}]+)}}/g, (match, variable) => {
          const variableKey = this.processVariable(variable);
          return this.cachedRules[variableKey] || `{{${variable}}}`;
        });
      }
      return tmpString.match(/{{([^}]+)}}/g);
    },
  },
  mounted() {
    this.$store.dispatch('agentBots/get');
    this.$track(CAMPAIGNS_EVENTS.OPEN_NEW_CAMPAIGN_MODAL, {
      type: this.campaignType,
    });
  },
  methods: {
    resetForm() {
      this.attachedFiles = [];
      this.selectedWhatsappTemplate = null;
      if (this.$v.selectedWhatsappTemplate)
        this.$v.selectedWhatsappTemplate.$reset();
      this.selectedMessageType = null;
      if (this.$v.selectedMessageType) this.$v.selectedMessageType.$reset();
      this.selectedMessageTag = null;
      if (this.$v.selectedMessageTag) this.$v.selectedMessageTag.$reset();
    },
    onIndirectFileUpload(file) {
      const MAXIMUM_SUPPORTED_FILE_UPLOAD_SIZE = MAXIMUM_FILE_UPLOAD_SIZE;
      if (!file) {
        return;
      }
      if (checkFileSizeLimit(file, MAXIMUM_SUPPORTED_FILE_UPLOAD_SIZE)) {
        this.attachFile({ file });
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_SUPPORTED_FILE_UPLOAD_SIZE,
          })
        );
      }
    },
    attachFile({ blob, file }) {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onloadend = () => {
        this.attachedFiles.push({
          resource: blob || file,
          thumb: reader.result,
          blobSignedId: blob ? blob.signed_id : undefined,
        });
      };
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },
    onPaste(e) {
      const data = e.clipboardData.files;
      if (!this.showRichContentEditor && data.length !== 0) {
        this.$refs.messageInput.$el.blur();
      }
      if (!data.length || !data[0]) {
        return;
      }
      data.forEach(file => {
        const { name, type, size } = file;
        this.onFileUpload({ name, type, size, file: file });
      });
    },
    onClose() {
      this.$emit('on-close');
    },
    onChange(value) {
      this.scheduledAt = value;
    },
    async onChangeInbox() {
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.selectedInbox,
        });
        const {
          data: { payload: inboxMembers },
        } = response;
        this.senderList = inboxMembers;
        this.resetForm();
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    onChangeTemplate() {
      this.generateVariables();
    },
    getCampaignDetails: function () {
      let campaignDetails = null;
      if (this.isOngoingType) {
        campaignDetails = {
          title: this.title,
          message: this.message,
          inbox_id: this.selectedInbox,
          sender_id: this.selectedSender || null,
          enabled: this.enabled,
          trigger_only_during_business_hours:
            this.triggerOnlyDuringBusinessHours,
          trigger_rules: {
            url: this.endPoint,
            time_on_page: this.timeOnPage,
          },
        };
      } else {
        const audience = this.selectedAudience.flat().map(item => {
          return {
            id: item.id,
            type: 'Label',
          };
        });

        let audienceConditions = [];
        let currentAndValues = [];
        for (let i = 0; i < this.selectedAudienceOperator.length; i++) {
          if (
            this.selectedAudienceOperator[i] === 'or' &&
            currentAndValues.length === 0
          ) {
            audienceConditions = [
              ...audienceConditions,
              ...this.selectedAudience[i].map(item => item.id),
            ];
            if (i === this.selectedAudienceOperator.length - 1)
              audienceConditions = [
                ...audienceConditions,
                ...this.selectedAudience[i + 1].map(item => item.id),
              ];
          } else if (
            this.selectedAudienceOperator[i] === 'or' &&
            currentAndValues.length > 0
          ) {
            audienceConditions.push({
              operator: 'and',
              values: JSON.stringify(currentAndValues),
            });
            currentAndValues = [];
          } else if (this.selectedAudienceOperator[i] === 'and') {
            if (currentAndValues.length === 0)
              currentAndValues.push(
                this.selectedAudience[i].map(item => item.id)
              );
            currentAndValues.push(
              this.selectedAudience[i + 1].map(item => item.id)
            );
            if (i === this.selectedAudienceOperator.length - 1)
              audienceConditions = [
                ...audienceConditions,
                {
                  operator: 'and',
                  values: JSON.stringify(currentAndValues),
                },
              ];
          }
        }
        campaignDetails = {
          title: this.title,
          message:
            this.isOneOffType && this.isWhatsapp
              ? this.processedString
              : this.message,
          inbox_id: this.selectedInbox,
          scheduled_at: this.scheduledAt,
          audience,
          trigger_rules: {
            audience_conditions: audienceConditions,
          },
        };
        if (this.isOneOffType && this.isWhatsapp) {
          let tempProcessedParams = {};
          if (Object.keys(this.cachedRules).length > 0) {
            Object.keys(this.cachedRules).forEach(key => {
              tempProcessedParams[key] = this.cachedRules[key].replace(
                /{{([^}]+)}}/g,
                (match, variable) => {
                  const variableKey = this.processVariable(variable);
                  return this.processedParams[variableKey] || `{{${variable}}}`;
                }
              );
            });
          } else {
            Object.keys(this.processedParams).forEach(key => {
              tempProcessedParams[key] = this.processedParams[key]
                .replace(/\t/g, '')
                .trim();
            });
          }
          campaignDetails.trigger_rules.template_params = {
            id: this.selectedWhatsappTemplate.id,
            name: this.selectedWhatsappTemplate.name,
            category: this.selectedWhatsappTemplate.category,
            language: this.selectedWhatsappTemplate.language,
            namespace: this.selectedWhatsappTemplate.namespace,
            header_params: this.headerParameters,
            processed_params: tempProcessedParams,
          };
        } else if (
          this.isOneOffType &&
          (this.isLine || this.isFb) &&
          this.attachedFiles.length !== 0
        ) {
          campaignDetails = new FormData();
          campaignDetails.append('title', this.title);
          campaignDetails.append(
            'message',
            this.isOneOffType && this.isWhatsapp
              ? this.processedString
              : this.message
          );
          campaignDetails.append('inbox_id', this.selectedInbox);
          campaignDetails.append('scheduled_at', this.scheduledAt);
          campaignDetails.append('audience', JSON.stringify(audience));
          if (this.isFb) {
            if (this.selectedMessageType === 'MESSAGE_TAG') {
              campaignDetails.append(
                'trigger_rules',
                JSON.stringify({
                  message_type: 'MESSAGE_TAG',
                  message_tag: this.selectedMessageTag,
                  is_before_24h: this.isBefore24h,
                  audience_conditions: audienceConditions,
                })
              );
            } else {
              campaignDetails.append(
                'trigger_rules',
                JSON.stringify({
                  message_type: this.selectedMessageType,
                  is_before_24h: this.isBefore24h,
                  audience_conditions: audienceConditions,
                })
              );
            }
          }
          this.attachedFiles.forEach(file => {
            file.resource &&
              campaignDetails.append('attachments[]', file.resource.file);
          });
        }
      }
      return campaignDetails;
    },
    async addCampaign() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        const campaignDetails = this.getCampaignDetails();
        await this.$store.dispatch('campaigns/create', campaignDetails);

        // tracking this here instead of the store to track the type of campaign
        this.$track(CAMPAIGNS_EVENTS.CREATE_CAMPAIGN, {
          type: this.campaignType,
        });

        this.showAlert(this.$t('CAMPAIGN.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    generateVariables() {
      const agentBot =
        this.agentBots.find(
          agent =>
            this.selectedInbox &&
            agent.name ===
              this.inboxes.filter(inbox => inbox.id === this.selectedInbox)[0]
                .phone_number
        ) ?? {};
      let botSystemPrompt = '';
      if (agentBot && agentBot.bot_config?.system_prompt)
        botSystemPrompt = JSON.parse(agentBot.bot_config.system_prompt);
      let variableDict =
        botSystemPrompt && botSystemPrompt[this.selectedWhatsappTemplate.name]
          ? botSystemPrompt[this.selectedWhatsappTemplate.name]
          : {};

      if ('variable' in variableDict) {
        this.cachedRules = { ...variableDict };
        delete this.cachedRules.variable;
        variableDict = variableDict.variable;
      }

      let tmpString = this.templateString;
      if (Object.keys(this.cachedRules).length > 0) {
        tmpString = tmpString.replace(/{{([^}]+)}}/g, (match, variable) => {
          const variableKey = this.processVariable(variable);
          return this.cachedRules[variableKey] || `{{${variable}}}`;
        });
      }
      const matchedVariables = tmpString.match(/{{([^}]+)}}/g);
      if (!matchedVariables) return;

      const variables = matchedVariables.map(i => this.processVariable(i));
      this.processedParams = variables.reduce((acc, variable) => {
        acc[variable] =
          variableDict && variableDict[variable] ? variableDict[variable] : '';
        return acc;
      }, {});
    },
    hasHeaderTemplate(template) {
      return !!template.components.find(
        component =>
          component.type === 'HEADER' &&
          ['IMAGE', 'VIDEO', 'DOCUMENT'].includes(component.format)
      );
    },
    processVariable(str) {
      return str.replace(/{{|}}/g, '');
    },
    getInputErrorClass(hasError) {
      return hasError
        ? 'bg-red-50 dark:bg-red-800/50 rounded-lg border-red-100 dark:border-red-700/50'
        : 'bg-slate-50 dark:bg-slate-800 rounded-lg border-slate-75 dark:border-slate-700/50';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';

::v-deep .ProseMirror-woot-style {
  height: 5rem;
}

.message-editor {
  @apply px-3;

  ::v-deep {
    .ProseMirror-menubar {
      @apply rounded-tl-[4px];
    }
  }
}

.template__variables-container {
  @apply p-2.5;
}

.variables-label {
  @apply text-sm font-semibold mb-2.5;
}

.template__variable-item {
  @apply items-center flex mb-2.5;

  .label {
    @apply text-xs;
  }

  .variable-input {
    @apply flex-1 text-sm ml-2.5;
  }

  .variable-label {
    @apply bg-slate-75 dark:bg-slate-700 text-slate-700 dark:text-slate-100 inline-block rounded-md text-xs py-2.5 px-6;
  }
}

.template-input {
  @apply bg-slate-25 dark:bg-slate-900 text-slate-700 dark:text-slate-100;
}

.message_filter--checkbox {
  font-size: $font-size-large;
}

.audience-error {
  @apply text-red-500 dark:text-red-200 block my-1 mx-0;
}
</style>
